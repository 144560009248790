<template>
  <div class="navbar-item-container search-form__wrapper"
       v-on-clickaway="close">
    <a class="navbar-item navbar-item-hide-all is-hidden-mobile is-condensed no-icon-margin search-icon"
       :class="{ 'is-active': formVisible }"
       @click.prevent="toggleFormVisible">
      <span class="icon-search"></span>
      <span class="navbar-item__text">{{ $t('generic.term.search') }}</span>
    </a>
    <div class="search-form__dropdown" v-if="formVisible">
      <search-modal :redesign="false" :method-name="methodName" :stream-code="streamCode" />
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import SearchModal from './SearchModal'

export default {
  components: { SearchModal },
  mixins: [
    clickaway
  ],
  props: {
    methodName: {
      type: String,
      default: ''
    },
    streamCode: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      formVisible: false
    }
  },
  methods: {
    toggleFormVisible () {
      this.formVisible = !this.formVisible
    },
    close () {
      this.formVisible = false
    }
  }
}
</script>
