<template>
  <div class="navbar-item-container">
    <a
      class="navbar-item navbar-item-hide-all is-condensed no-icon-margin notifications-icon"
      :class="{ 'is-active': previewVisible }"
      @click.prevent="togglePreviewVisible"
    >
        <span class="icon-bell"></span>
        <span v-if="hasNew" class="notifications-icon__new-notifications-marker"></span>
    </a>
    <notifications-preview
      v-if="previewVisible"
      :notification-service="notificationService"
      v-on:show-notifications-modal="showModal"
      v-on-clickaway="closePreview"
    ></notifications-preview>
  </div>
</template>

<script>
  import { EventBus, NOTIFICATIONS_MODAL_OPEN_EVENT } from '../../common/EventBus'
  import NotificationService from './NotificationService'
  import NotificationsPreview from './NotificationsPreview'
  import { mixin as clickaway } from 'vue-clickaway'

  export default {
    mixins: [clickaway],
    components: {
      NotificationsPreview
    },
    data() {
      return {
        previewVisible: false,
        notificationService: null
      }
    },
    props: {
      hasNewNotifications: Boolean,
      methodSlug: String
    },
    computed: {
      hasNew() {
        return this.notificationService.getHasNewNotifications()
      }
    },
    created() {
      this.notificationService = new NotificationService(
        this.methodSlug,
        this.hasNewNotifications
      )
    },
    methods: {
      showModal() {
        this.closePreview()
        EventBus.$emit(NOTIFICATIONS_MODAL_OPEN_EVENT, this.notificationService)
      },
      closePreview() {
        this.previewVisible = false
      },
      togglePreviewVisible() {
        this.previewVisible = !this.previewVisible
      }
    }
  }
</script>
