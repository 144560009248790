var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      class: { "modal--fullscreen": _vm.fullscreen },
      attrs: {
        loading: _vm.loading,
        "close-button-inside": true,
        "show-close-button": _vm.showCloseButton,
      },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        { staticClass: "confirm-modal__content" },
        [
          _c("h1", {
            staticClass: "title",
            domProps: { innerHTML: _vm._s(_vm.title) },
          }),
          _vm._v(" "),
          _vm._t("content"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flexbox flex-align-items--center",
              class: { "flex-justify-content--end": !_vm.secondaryActionLabel },
            },
            [
              _vm.$slots["primary-action"]
                ? _c(
                    "a",
                    {
                      staticClass: "confirm-modal__primary-action",
                      attrs: {
                        id: "modal-confirm-primary-action",
                        "data-test": "button-primary-action",
                        href: _vm.primaryHref,
                      },
                      on: { click: _vm.primaryAction },
                    },
                    [_vm._t("primary-action")],
                    2
                  )
                : _c(
                    "a",
                    {
                      staticClass: "button button--primary",
                      class: {
                        "button--disabled": _vm.primaryDisabled,
                        "confirm-modal__primary-action":
                          _vm.secondaryActionLabel,
                      },
                      attrs: {
                        href: _vm.primaryHref,
                        id: "modal-confirm-primary-action",
                        "data-test": "button-primary-action",
                      },
                      on: { click: _vm.primaryAction },
                    },
                    [_vm._v(_vm._s(_vm.primaryActionLabel))]
                  ),
              _vm._v(" "),
              _vm.$slots["secondary-action"]
                ? _c(
                    "a",
                    {
                      staticClass: "confirm-modal__secondary-action",
                      class: { disabled: _vm.secondaryDisabled },
                      attrs: { href: _vm.secondaryHref },
                      on: { click: _vm.secondaryAction },
                    },
                    [_vm._t("secondary-action")],
                    2
                  )
                : _c(
                    "a",
                    {
                      staticClass: "confirm-modal__secondary-action",
                      class: { disabled: _vm.secondaryDisabled },
                      attrs: { href: _vm.secondaryHref },
                      on: { click: _vm.secondaryAction },
                    },
                    [_vm._v(_vm._s(_vm.secondaryActionLabel))]
                  ),
            ]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }