var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navbar-item-container" },
    [
      _c(
        "a",
        {
          staticClass:
            "navbar-item navbar-item-hide-all is-condensed no-icon-margin notifications-icon",
          class: { "is-active": _vm.previewVisible },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.togglePreviewVisible.apply(null, arguments)
            },
          },
        },
        [
          _c("span", { staticClass: "icon-bell" }),
          _vm._v(" "),
          _vm.hasNew
            ? _c("span", {
                staticClass: "notifications-icon__new-notifications-marker",
              })
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.previewVisible
        ? _c("notifications-preview", {
            directives: [
              {
                name: "on-clickaway",
                rawName: "v-on-clickaway",
                value: _vm.closePreview,
                expression: "closePreview",
              },
            ],
            attrs: { "notification-service": _vm.notificationService },
            on: { "show-notifications-modal": _vm.showModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }