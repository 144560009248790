var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.close,
          expression: "close",
        },
      ],
      staticClass: "navbar-item-container search-form__wrapper",
    },
    [
      _c(
        "a",
        {
          staticClass:
            "navbar-item navbar-item-hide-all is-hidden-mobile is-condensed no-icon-margin search-icon",
          class: { "is-active": _vm.formVisible },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.toggleFormVisible.apply(null, arguments)
            },
          },
        },
        [
          _c("span", { staticClass: "icon-search" }),
          _vm._v(" "),
          _c("span", { staticClass: "navbar-item__text" }, [
            _vm._v(_vm._s(_vm.$t("generic.term.search"))),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.formVisible
        ? _c(
            "div",
            { staticClass: "search-form__dropdown" },
            [
              _c("search-modal", {
                attrs: {
                  redesign: false,
                  "method-name": _vm.methodName,
                  "stream-code": _vm.streamCode,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }